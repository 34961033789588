import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const addTagAsync = createAsyncThunk('auth/addTagIn',async (data,thunkAPI) => {
    try{
        await axios(data);
   
        return { isError : false, isLoading : false,isAdded : true , errorMessage : '' }
     
    }catch (error){
       const { response: { status, data: responseData } } = error;
       if(status === 400){
           return { isError : true, isLoading : false, isadded:false, errorMessage : responseData.message }
       }
       return thunkAPI.rejectWithValue(error);
    }
});

const addTagSlice = createSlice({
    name : 'addTag',
    initialState : {
        isLoading: false,
        isError: false,
        errorMessage: '',
        isAdded: false,
    },
    reducers: {
        clearAuthState : (state) => {
            state.isLoading = false;
            state.isError = false;
            state.errorMessage = '';
            state.isAdded = false;
        }
    },
    extraReducers: {
        [addTagAsync.pending] : (state) => {
            state.isLoading = true;
        },
        [addTagAsync.fulfilled] : (state,action) => {
            const { isLoading,isError,errorMessage,isAdded } = action.payload;
            state.isLoading = isLoading;
            state.isError = isError;
            state.errorMessage = errorMessage;
            state.isAdded = isAdded;
        },
        [addTagAsync.rejected] : (state,{payload}) => {
            if(payload){
                state.isError = payload.isError;
                state.errorMessage = payload.errorMessage;
            }else{
                state.isError = true;
                state.errorMessage = 'Internal Server Error';
            }
        },
    }
});

export const addTagSelector = (state) => state.reducer.addTag;
export default addTagSlice.reducer;
export const { data , clearAuthState } = addTagSlice.actions;
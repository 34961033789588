import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import logo200Image from 'assets/img/users/user.png';
import React,{useState} from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdLock
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import bn from 'utils/bemnames';
import axios from 'axios';
import {IDENTITY_SERVICE_URL} from '../../config';
import Storage from '../../utils/storage';
const storage = new Storage();
const bem = bn.create('header');

const Header = ()=>{
  const [isOpenUserCardPopover,setIsOpenUserCardPopover]=useState(false);
  const history=useHistory();
  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  }
  const signout =()=>{
    axios({
      url:IDENTITY_SERVICE_URL+'/logout',
      method:'POST',
      headers:{
        Authorization:storage.get('token')
      }
    });
    storage.delete('token');
    history.push('/login');
  }
  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    var toggle = document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    if(toggle){
      document.getElementsByClassName('cr-content')[0].style.width = "83.5%";
    }else{
      document.getElementsByClassName('cr-content')[0].style.width = "100%";
    }
  }
  const changePassword =()=>{
    toggleUserCardPopover();
    history.push('/change-password');
  }
  return (
    <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={toggleUserCardPopover}
                className="can-click"
                style={{background:'white'}}
                src={logo200Image}
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={isOpenUserCardPopover}
              toggle={toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={'Lash Traders'}
                  subtitle={'Super Admin'}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" onClick={changePassword} action className="border-light">
                      <MdLock /> Change Password
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light" onClick={signout}>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
  )
}

export default Header;


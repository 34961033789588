import AuthForm from './login/AuthForm';
import React from 'react';
class AuthPage extends React.Component {
  render() {
    return (
      <AuthForm
        authState={this.props.authState}
        onChangeAuthState={this.handleAuthState}
        onLogoClick={this.handleLogoClick}
      />
    );
  }
}

export default AuthPage;

import React,{useState,useEffect} from 'react';
import { Button, Form, FormGroup,Row, Input, Label,Col,CardBody,Card, Spinner } from 'reactstrap';
import { useDispatch ,useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signInAsync , signInSelector , clearAuthState } from './signin-slice';
import { IDENTITY_SERVICE_URL } from '../../config';
import logo200Image from 'assets/img/logo/logo.png';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import {getFToken} from '../../utils/firebase';
import Storage from '../../utils/storage';
import axios from 'axios';

const AuthForm = ({
  showLogo,
  usernameLabel,
  usernameInputProps,
  passwordLabel,
  passwordInputProps,
  onLogoClick
}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const storage =new Storage();
  const [userData , setUserData] = useState({
    email : '',
    password : '',
    deviceToken:'',
    deviceType:'WEB',

  });
  const [isLoadPage , setIsLoadPage] = useState(true);
  const [userDataError , setUserDataError] = useState({
    emailErr : '',
    passwordErr : '',
  });
  const { isError,errorMessage,signInSuccess } = useSelector(signInSelector);
  const PushNoti = async () => {
    const fToken = await getFToken();
    if(fToken){
      console.log(fToken);
        setUserData({...userData,deviceToken:fToken})
    }
  }
  const toDashboard=async ()=>{
    await axios({
      url:IDENTITY_SERVICE_URL+'/dashboard',
      method:'GET',
      headers:{
        Authorization:storage.get('token')
      }
    }).then(response => {
      history.push('/');      
    }).catch(error => {
      if(typeof error.response !=='undefined' && typeof error.response.status!=='undefined'){
        if(error.response.status===401){
          setIsLoadPage(false);
        }
    }
    });
  }
  useEffect(() => {
    toDashboard();
    PushNoti();
  },[]);
  


  const onHandleChange = (e) => {
    const {name,value} = e.target;
    if(name === "email"){
      setUserDataError({...userDataError,emailErr: ''})
    }
    if(name === "password"){
      setUserDataError({...userDataError,passwordErr: ''})
    }
    setUserData({...userData,[name]:value})
  }

  const signinHandler = (e) => {
    e.preventDefault();
    if (!userData.email) {
        setUserDataError({...userDataError,emailErr: 'Email Field is Required'});
        return false;
    } else if(!userData.password){
        setUserDataError({...userDataError,passwordErr: 'Password Field is Required'})
         return false;
    }else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(userData.email)) {
      setUserDataError({...userDataError,emailErr:'Invalid Email'});
      return false;
    }

    if(userDataError.emailErr === '' && userDataError.passwordErr === ''){
      console.log(userDataError);
      const data = {
        data: userData,
        url: `${IDENTITY_SERVICE_URL}/login`,
        method: 'post'
      }
      dispatch(signInAsync(data));
    }
  }


  useEffect(()=>{
    if(isError){
        // dispatch(clearAuthState());
        //toastr.error(errorMessage);
    }
    if(signInSuccess){
        dispatch(clearAuthState());
        //toastr.success("Login Success");
        history.push('/');
    }
  
  }, [signInSuccess, isError]);

    return (
      <div className="content">
            <Row className="justify-content-center" style={{height: '70vh',alignItems:'center',marginRight:0,marginLeft:0}}>
                <Col md={6} lg={4}>
                    <Card>
                        <CardBody>
                          {!isLoadPage?
                            <Form className="mb-4">
                              {showLogo && (
                                <div className="text-center pb-4">
                                  <img
                                    src={logo200Image}
                                    className="rounded"
                                    style={{ width: 120, cursor: 'pointer' }}
                                    alt="logo"
                                    onClick={onLogoClick}
                                  />
                                  <h3 className="mt-4">Admin Login</h3>
                                </div>
                              )}
                              <FormGroup>
                                <Label for={usernameLabel}>{usernameLabel}</Label>
                                <Input {...usernameInputProps} onChange={onHandleChange}/>
                              </FormGroup>
                              <Typography className="text-danger">
                                      {userDataError.emailErr ? userDataError.emailErr : ''}
                              </Typography>
                              <FormGroup>
                                <Label for={passwordLabel}>{passwordLabel}</Label>
                                <Input {...passwordInputProps} onChange={onHandleChange}/>

                              </FormGroup>
                              <Typography className="text-danger">
                                      {userDataError.passwordErr? userDataError.passwordErr : ''}
                              </Typography>
                              {isError && <Typography className="text-danger">
                                      {errorMessage}
                              </Typography>}

                              <Button
                                type="submit"
                                className="btn btn-secondary border-0"
                                block
                                onClick={signinHandler}>
                                Login
                              </Button>
                            </Form>
                          :<Spinner className="text-center" />}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
  }


AuthForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'Enter email address',
    name : 'email'
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Enter Password',
    name : 'password'
  },
  onLogoClick: () => {}
};

export default AuthForm;

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import promiseMiddleware from "redux-promise";
import thunk from "redux-thunk";
import { rootReducer } from "../reducers/rootreducer";
import logger from "redux-logger";

const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(promiseMiddleware, thunk, logger)
    )
  );
export default store;
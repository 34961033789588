/* export const IDENTITY_SERVICE_URL = 'https://api.supersourcing.net/admin';
export const SELLER_SERVICE_URL = 'https://api.supersourcing.net/seller';
export const SOCKET_ENDPOINTS = 'https://api.supersourcing.net/'; */
/* export const IDENTITY_SERVICE_URL = 'https://dev.api.supersourcing.net/admin'; 
export const SELLER_SERVICE_URL = 'https://dev.api.supersourcing.net/seller';
export const SOCKET_ENDPOINTS = 'https://dev.api.supersourcing.net/'; */
// export const IDENTITY_SERVICE_URL = 'http://13.52.60.169:8890/admin'; 
// export const SELLER_SERVICE_URL = 'http://13.52.60.169:8890/seller';
// export const SOCKET_ENDPOINTS = 'http://13.52.60.169:8890/'; 

export const IDENTITY_SERVICE_URL = 'https://dev.api.supersourcing.net/admin'; 
export const SELLER_SERVICE_URL = 'https://dev.api.supersourcing.net/seller';
export const SOCKET_ENDPOINTS = 'https://dev.api.supersourcing.net/'; 

// export const IDENTITY_SERVICE_URL = 'http://localhost:8888/admin'; 
// export const SELLER_SERVICE_URL = 'http://localhost:8888/seller';
// export const SOCKET_ENDPOINTS = 'http://localhost:8888/';

import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addColorAsync = createAsyncThunk('auth/addColorIn',async (data,thunkAPI) => {
    try{
        await axios(data);
     return { isError : false, isLoading : false,isAdded : true, errorMessage : '' }
     
    }catch (error){
       const { response: { status, data: responseData } } = error;
       if(status === 400){
           return { isError : true, isLoading : false, isAdded:false , errorMessage : responseData.message }
       }
       return thunkAPI.rejectWithValue(error);
    }
});

const addColorSlice = createSlice({
    name : 'addColor',
    initialState : {
        isLoading: false,
        isError: false,
        errorMessage: '',
        isAdded: false,
    },
    reducers: {
        clearAuthState : (state) => {
            state.isLoading = false;
            state.isError = false;
            state.errorMessage = '';
            state.isAdded = false;
        }
    },
    extraReducers: {
        [addColorAsync.pending] : (state) => {
            state.isLoading = true;
        },
        [addColorAsync.fulfilled] : (state,action) => {
            const { isLoading,isError,errorMessage,isAdded } = action.payload;
            state.isLoading = isLoading;
            state.isError = isError;
            state.errorMessage = errorMessage;
            state.isAdded = isAdded;
        },
        [addColorAsync.rejected] : (state,{payload}) => {
            if(payload){
                state.isError = payload.isError;
                state.errorMessage = payload.errorMessage;
            }else{
                state.isError = true;
                state.errorMessage = 'Internal Server Error';
            }
        },
    }
});

export const addColorSelector = (state) => state.reducer.addColor;
export default addColorSlice.reducer;
export const { data , clearAuthState } = addColorSlice.actions;
import './styles/reduction.scss';
import './styles/new-style.css';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import PrivateRoutes from './custom-routes';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Switch} from 'react-router-dom';
import {getFToken,onMessageListener} from './utils/firebase';

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  constructor(props) {
    super(props);
    onMessageListener();
    getFToken();
  }
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} />
              )}
            />
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <PrivateRoutes exact path="/" component={React.lazy(() => import('pages/DashboardPage'))} />
                <PrivateRoutes exact path="/categories" component={React.lazy(() => import('pages/catalog/categories'))} />
                <PrivateRoutes exact path="/sort-category" component={React.lazy(() => import('pages/catalog/categories/CategorySort'))} />
                <PrivateRoutes exact path="/users" component={React.lazy(() => import('pages/users/Users'))} />
                <PrivateRoutes exact path="/deleted-users" component={React.lazy(() => import('pages/users/DeletedUser'))} />
                {/* <PrivateRoutes exact path="/deleted-user-detail/:userId" component={React.lazy(() => import('pages/users/DeletedUserDetail'))} /> */}
                <PrivateRoutes exact path="/edit-user/:userId" component={React.lazy(() => import('pages/users/EditUser'))} />
                <PrivateRoutes exact path="/user-detail/:userId" component={React.lazy(() => import('pages/users/UserDetail'))} />
                <PrivateRoutes exact path="/sellers" component={React.lazy(() => import('pages/stores/Stores'))} />
                <PrivateRoutes exact path="/sort-sellers" component={React.lazy(() => import('pages/stores/SortStores'))} />
                <PrivateRoutes exact path="/seller-requests" component={React.lazy(() => import('pages/stores/PendingStores'))} />
                <PrivateRoutes exact path="/edit-seller/:storeId" component={React.lazy(() => import('pages/stores/EditStore'))} />
                <PrivateRoutes exact path="/seller-detail/:storeId" component={React.lazy(() => import('pages/stores/StoreDetail'))} />
                <PrivateRoutes exact path="/stores-information" component={React.lazy(() => import('pages/stores/StoreInformation'))} />
                <PrivateRoutes exact path="/brands" component={React.lazy(() => import('pages/catalog/brands'))}/>
                <PrivateRoutes exact path="/products" component={React.lazy(() => import('pages/catalog/products/index.new'))}/>
                <PrivateRoutes exact path="/product-detail/:id" component={React.lazy(() => import('pages/catalog/products/detail'))}/>
                <PrivateRoutes exact path="/add-product" component={React.lazy(() => import('pages/catalog/products/add'))}/>
                <PrivateRoutes exact path="/edit-product/:id" component={React.lazy(() => import('pages/catalog/products/edit'))}/>
                <PrivateRoutes exact path="/sizes" component={React.lazy(() => import('pages/catalog/sizes'))}/>
                <PrivateRoutes exact path="/colors" component={React.lazy(() => import('pages/catalog/colors'))}/>
                <PrivateRoutes exact path="/tags" component={React.lazy(() => import('pages/catalog/tags'))}/>
                <PrivateRoutes exact path="/orders" component={React.lazy(() => import('pages/sales/Order'))}/>
                <PrivateRoutes exact path="/order-refund-requests" component={React.lazy(() => import('pages/sales/RefundOrderRequest'))}/>
                <PrivateRoutes exact path="/order-refund-requests/:id" component={React.lazy(() => import('pages/sales/RefundOrderRequestDetail'))}/>
                <PrivateRoutes exact path="/order-return-requests" component={React.lazy(() => import('pages/sales/OrderReturnRequests'))}/>
                <PrivateRoutes exact path="/order-replace-requests" component={React.lazy(() => import('pages/sales/ReplaceOrderRequests'))}/>
                <PrivateRoutes exact path="/order-return-requests-detail/:id" component={React.lazy(() => import('pages/sales/OrderReturnRequestsDetail'))}/>
                <PrivateRoutes exact path="/order-replace-requests-detail/:id" component={React.lazy(() => import('pages/sales/OrderReplaceRequestsDetail'))}/>
                <PrivateRoutes exact path="/order-detail/:id" component={React.lazy(() => import('pages/sales/OrderDetail'))}/>
                <PrivateRoutes exact path="/orders/generate-shipping-label/:id" component={React.lazy(() => import('pages/sales/ShippingLabel'))}/>
                <PrivateRoutes exact path="/add-category" component={React.lazy(() => import('pages/catalog/categories/add'))}/>
                <PrivateRoutes exact path="/edit-category/:id" component={React.lazy(() => import('pages/catalog/categories/edit'))}/>
                <PrivateRoutes exact path="/transactions" component={React.lazy(() => import('pages/sales/transactions/History'))}/>
                <PrivateRoutes exact path="/change-password" component={React.lazy(() => import('pages/profile/ChangePassword'))}/>
                <PrivateRoutes exact path="/lt-prices" component={React.lazy(() => import('pages/settings/LtPrices'))}/>
                <PrivateRoutes exact path="/lt-coins" component={React.lazy(() => import('pages/settings/LtCoins'))}/>
                <PrivateRoutes exact path="/seller-chats/:id?" component={React.lazy(() => import('pages/stores/Chatting.js'))}/>
                <PrivateRoutes exact path="/user-chats/:id?" component={React.lazy(() => import('pages/users/Chatting.js'))}/>
                <PrivateRoutes exact path="/user-seller-chats" component={React.lazy(() => import('pages/chats/Chatting.js'))}/>
                <PrivateRoutes exact path="/user-seller-chats/:id" component={React.lazy(() => import('pages/chats/ChatDetail.js'))}/>
                <PrivateRoutes exact path="/promocode" component={React.lazy(() => import('pages/settings/Promocode'))} />
                <PrivateRoutes exact path="/add-promocode" component={React.lazy(() => import('pages/settings/AddPromocode'))} />
                <PrivateRoutes exact path="/edit-promocode/:id" component={React.lazy(() => import('pages/settings/AddPromocode'))} />
                <PrivateRoutes exact path="/payments" component={React.lazy(() => import('pages/payments/WithdrawRequest'))} />
                <PrivateRoutes exact path="/return-reasons" component={React.lazy(() => import('pages/settings/ReturnProductReason'))} />
                <PrivateRoutes exact path="/device-activity" component={React.lazy(() => import('pages/settings/DeviceActivity'))} />
                <PrivateRoutes exact path="/banners" component={React.lazy(() => import('pages/settings/Banner'))} />
                <PrivateRoutes exact path="/shipping-labels" component={React.lazy(() => import('pages/settings/ShippingLabelList'))} />
                <PrivateRoutes exact path="/return-labels" component={React.lazy(() => import('pages/settings/ReturnLabelList'))} />
                <PrivateRoutes exact path="/review-rating" component={React.lazy(() => import('pages/reviewRatings/ReviewAndRatings'))} />
                <PrivateRoutes exact path="/review-detail/:id" component={React.lazy(() => import('pages/reviewRatings/Details'))}/>


                <PrivateRoutes exact path="/download-reports" component={React.lazy(() => import('pages/reports/Download'))}/>
                <PrivateRoutes exact path="/redirect-download-reports" component={React.lazy(() => import('pages/reports/RedirectDownload'))}/>
                <PrivateRoutes exact path="/referral" component={React.lazy(() => import('pages/referral/Manage'))}/>
              </React.Suspense>
            </MainLayout>
          </Switch>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);

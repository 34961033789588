import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const approveProductAsync = createAsyncThunk('auth/approveProductIn',async (data,thunkAPI) => {
    try{
        
     const res = await axios(data);
   
     return { updateError : false, updateLoading : false,isUpdating : true, updateerrMessage : '' ,updateSuccessMessage : res.data.data}
     
    }catch (error){
       const { response: { status, data: responseData } } = error;
       if(status === 400){
           return { updateError : true, updateLoading : false, isUpdating:false ,updateerrMessage : responseData.message ,updateSuccessMessage :''}
       }
       return thunkAPI.rejectWithValue(error);
    }
});

const approveProductSlice = createSlice({
    name : 'approveProduct',
    initialState : {
        updateLoading: false,
        updateError: false,
        updateerrMessage: '',
        isUpdating: false,
        updateSuccessMessage:''
    },
    reducers: {
        clearAuthState : (state) => {
            state.updateLoading = false;
            state.updateError = false;
            state.updateerrMessage = '';
            state.isUpdating = false;
            state.updateSuccessMessage ='';
        }
    },
    extraReducers: {
        [approveProductAsync.pending] : (state) => {
            state.updateLoading = true;
        },
        [approveProductAsync.fulfilled] : (state,action) => {
            const { updateLoading,updateError,updateerrMessage,updateSuccessMessage,isUpdating } = action.payload;
            state.updateLoading = updateLoading;
            state.updateError = updateError;
            state.updateerrMessage = updateerrMessage;
            state.isUpdating = isUpdating;
            state.updateSuccessMessage = updateSuccessMessage;
        },
        [approveProductAsync.rejected] : (state,{payload}) => {
            if(payload){
                state.updateError = payload.updateError;
                state.updateerrMessage = payload.updateerrMessage;
            }else{
                state.updateError = true;
                state.updateerrMessage = 'Internal Server Error';
            }
        },
    }
});

export const approveProductSelector = (state) => state.reducer.approveProduct;
export default approveProductSlice.reducer;
export const { data , clearAuthState } = approveProductSlice.actions;